export const TOGGLE_DARK = 'TOGGLE_DARK'

export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'

export const SET_DECKS = 'SET_DECKS'
export const ADD_DECK = 'ADD_DECK'
export const SET_DECK = 'SET_DECK'
export const REMOVE_DECK = 'REMOVE_DECK'
export const ADD_CARD = 'ADD_CARD'
export const SET_CARD = 'SET_CARD'
export const DELETE_CARD = 'DELETE_CARD'
export const RESET_BUCKETS = 'RESET_BUCKETS'
export const SET_BUCKETS = 'SET_BUCKETS'
export const ADD_CARD_TO_BUCKETS = 'ADD_CARD_TO_BUCKETS'
export const REMOVE_CARD_FROM_BUCKETS = 'REMOVE_CARD_FROM_BUCKETS'
